import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import LayoutSimple from '../components/layout-simple'
import Breadcrumbs, { BreadcrumbsItem } from "@kiwicom/orbit-components/lib/Breadcrumbs";
import ChevronLeft from "@kiwicom/orbit-components/lib/icons/ChevronLeft"
import blob from '../blob/blob-shape_green_a.svg';
import styled from "styled-components";

const Gallery = styled('div')` 
    display: grid;
    grid-template-columns: repeat(2, 5fr);    
    grid-gap: 1.5rem;
`;

const BreadcrumbsItemLg = styled(BreadcrumbsItem)`
font-size: 1.5em;
`;

class GalleryPageTemplate extends React.Component {
  componentDidMount() {
    try {
      import('medium-zoom').then(mediumZoom => {
        // setZoom({zoom :  mediumZoom.default('img') });
        mediumZoom.default('img', { scrollOffset: 0,
          margin: 20,
          container: {
            top: 20
          },
          background: 'linear-gradient(45deg, rgb(0, 0, 0), rgba(0, 0, 0, 0.68))'});
      })
    } catch (e) {
      console.log("Couldn't execute from ComponentDidMount:", e);
    }
  }
  render() {
    const gallery = get(this.props, 'data.contentfulPhotoGallery')
    const images = get(this.props, 'data.contentfulPhotoGallery.photos')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    function goBack(e) {
      e.preventDefault();
      window.location.href='/gallery';
    }

    const Masonry = styled('masonry-layout')`
      display: none !important;
    `;

    return (
      <LayoutSimple location={this.props.location} >
        <div style={{ background: '#fff' }}>
          <Helmet title={`${gallery.title.title} | ${siteTitle}`} />
          {/*<div className={heroStyles.hero}>*/}
            {/*<Img className={heroStyles.heroImage} alt={gallery.title} fluid={gallery.heroImage.fluid} />*/}
          {/*</div>*/}
          <div className="wrapper" style={{position: 'relative'}}>
            <h1 className="section-headline">
              {gallery.title.title}
            </h1>
            <img className={'blob-gallery'} src={blob} />
            <Breadcrumbs onGoBack={goBack} spaceAfter={null}>
              <BreadcrumbsItemLg href="/" >
                Kingscote Doodles
              </BreadcrumbsItemLg>
              <BreadcrumbsItemLg href="/gallery" >
                Photo Gallery
              </BreadcrumbsItemLg>
              <BreadcrumbsItemLg href="#" >
                {`${gallery.title.title}`}
              </BreadcrumbsItemLg>
            </Breadcrumbs>


            <p
              style={{
                display: 'block',
              }}
            >
              {gallery.publishDate}
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html: gallery.description.childMarkdownRemark.html,
              }}
            />
          </div>

          <Gallery>
            {images.map( (node,index)  => {

              return (
                  <Img key={index} fluid={node.fluid} />
              )
            })}
          </Gallery>



          <Link to='/gallery'><ChevronLeft size={'large'} />Back to all Galleries</Link>

        </div>
      </LayoutSimple>
    )
  }
}

export default GalleryPageTemplate

export const pageQuery = graphql`
  query GalleryPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPhotoGallery(slug: { eq: $slug }) {
      id
      description {
        id
        childMarkdownRemark {
          html
        }
      }
      title {
        id
        title
      }
      photos {
        id
        title
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
        
      }
    }
  }
`
